<template>
  <div
    v-if="hasSecondaryMenu"
    class="h-full overflow-auto w-48 flex flex-col bg-white dark:bg-slate-900 border-r dark:border-slate-800/50 rtl:border-r-0 rtl:border-l border-slate-50 text-sm px-2 pb-8 rounded-2xl"
  >
    <account-context @toggle-accounts="toggleAccountModal" />
    <transition-group
      name="menu-list"
      tag="ul"
      class="pt-2 list-none ml-0 mb-0"
    >
      <secondary-nav-item
        v-for="menuItem in accessibleMenuItems"
        :key="menuItem.toState"
        :menu-item="menuItem"
      />
      <secondary-nav-item
        v-for="menuItem in additionalSecondaryMenuItems[menuConfig.parentNav]"
        :key="menuItem.key"
        :menu-item="menuItem"
        @add-label="showAddLabelPopup"
        @edit-label="showEditLabelPopup"
      />
    </transition-group>
  </div>
</template>
<script>
import { frontendURL } from '../../../helper/URLHelper';
import SecondaryNavItem from './SecondaryNavItem.vue';
import AccountContext from './AccountContext.vue';
import { mapGetters } from 'vuex';
import { FEATURE_FLAGS } from '../../../featureFlags';

export default {
  components: {
    AccountContext,
    SecondaryNavItem,
  },
  props: {
    accountId: {
      type: Number,
      default: 0,
    },
    labels: {
      type: Array,
      default: () => [],
    },
    inboxes: {
      type: Array,
      default: () => [],
    },
    teams: {
      type: Array,
      default: () => [],
    },
    customViews: {
      type: Array,
      default: () => [],
    },
    menuConfig: {
      type: Object,
      default: () => {},
    },
    currentRole: {
      type: String,
      default: '',
    },
    isOnChatwootCloud: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      statsRefreshTimer: null,
    };
  },
  computed: {
    ...mapGetters({
      isFeatureEnabledonAccount: 'accounts/isFeatureEnabledonAccount',
    }),
    hasSecondaryMenu() {
      return this.menuConfig.menuItems && this.menuConfig.menuItems.length;
    },
    contactCustomViews() {
      return this.customViews.filter(view => view.filter_type === 'contact');
    },
    accessibleMenuItems() {
      if (!this.currentRole) {
        return [];
      }
      const menuItemsFilteredByRole = this.menuConfig.menuItems.filter(
        menuItem =>
          window.roleWiseRoutes[this.currentRole].indexOf(
            menuItem.toStateName
          ) > -1
      );
      return menuItemsFilteredByRole.filter(item => {
        if (item.showOnlyOnCloud) {
          return this.isOnChatwootCloud;
        }
        return true;
      });
    },
    inboxSection() {
      return {
        icon: 'folder',
        label: 'INBOXES',
        hasSubMenu: true,
        newLink: this.showNewLink(FEATURE_FLAGS.INBOX_MANAGEMENT),
        newLinkTag: 'NEW_INBOX',
        key: 'inbox',
        toState: frontendURL(`accounts/${this.accountId}/settings/inboxes/new`),
        toStateName: 'settings_inbox_new',
        newLinkRouteName: 'settings_inbox_new',
        children: this.inboxes
          .map(inbox => ({
            id: inbox.id,
            label: inbox.name,
            truncateLabel: true,
            toState: frontendURL(
              `accounts/${this.accountId}/inbox/${inbox.id}`
            ),
            type: inbox.channel_type,
            phoneNumber: inbox.phone_number,
            reauthorizationRequired: inbox.reauthorization_required,
          }))
          .sort((a, b) =>
            a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
          ),
      };
    },
    labelSection() {
      return {
        icon: 'number-symbol',
        label: 'LABELS',
        hasSubMenu: true,
        newLink: this.showNewLink(FEATURE_FLAGS.TEAM_MANAGEMENT),
        newLinkTag: 'NEW_LABEL',
        key: 'label',
        toState: frontendURL(`accounts/${this.accountId}/settings/labels`),
        toStateName: 'labels_list',
        showModalForNewItem: true,
        modalName: 'AddLabel',
        dataTestid: 'sidebar-new-label-button',
        children: this.labels.map(label => ({
          id: label.id,
          label: label.title,
          color: label.color,
          truncateLabel: true,
          toState: frontendURL(`accounts/${this.accountId}/label/${label.id}`),
          description: label.description,
          show_on_sidebar: label.show_on_sidebar,
          parent_label_id: null,
          children: label.children
            ? label.children.map(subLabel => ({
                id: subLabel.id,
                label: subLabel.title,
                color: subLabel.color,
                truncateLabel: true,
                toState: frontendURL(
                  `accounts/${this.accountId}/label/${subLabel.id}`
                ),
                description: label.description,
                show_on_sidebar: label.show_on_sidebar,
                parent_label_id: label.id,
              }))
            : [],
        })),
      };
    },
    contactLabelSection() {
      return {
        icon: 'number-symbol',
        label: 'TAGGED_WITH',
        hasSubMenu: true,
        key: 'label',
        newLink: this.showNewLink(FEATURE_FLAGS.TEAM_MANAGEMENT),
        newLinkTag: 'NEW_LABEL',
        toState: frontendURL(`accounts/${this.accountId}/settings/labels`),
        toStateName: 'labels_list',
        showModalForNewItem: true,
        modalName: 'AddLabel',
        children: this.labels.map(label => ({
          id: label.id,
          label: label.title,
          color: label.color,
          truncateLabel: true,
          toState: frontendURL(
            `accounts/${this.accountId}/labels/${label.id}/contacts`
          ),
        })),
      };
    },
    teamSection() {
      return {
        icon: 'people-team',
        label: 'TEAMS',
        hasSubMenu: true,
        newLink: this.showNewLink(FEATURE_FLAGS.TEAM_MANAGEMENT),
        newLinkTag: 'NEW_TEAM',
        key: 'team',
        toState: frontendURL(`accounts/${this.accountId}/settings/teams/new`),
        toStateName: 'settings_teams_new',
        newLinkRouteName: 'settings_teams_new',
        children: this.teams.map(team => ({
          id: team.id,
          label: team.name,
          truncateLabel: true,
          toState: frontendURL(`accounts/${this.accountId}/team/${team.id}`),
        })),
      };
    },
    foldersSection() {
      return {
        icon: 'folder',
        label: 'CUSTOM_VIEWS_FOLDER',
        hasSubMenu: true,
        key: 'custom_view',
        children: this.customViews
          .filter(view => view.filter_type === 'conversation')
          .map(view => ({
            id: view.id,
            label: view.name,
            truncateLabel: true,
            toState: frontendURL(
              `accounts/${this.accountId}/custom_view/${view.id}`
            ),
          })),
      };
    },
    contactSegmentsSection() {
      return {
        icon: 'folder',
        label: 'CUSTOM_VIEWS_SEGMENTS',
        hasSubMenu: true,
        key: 'custom_view',
        children: this.customViews
          .filter(view => view.filter_type === 'contact')
          .map(view => ({
            id: view.id,
            label: view.name,
            truncateLabel: true,
            toState: frontendURL(
              `accounts/${this.accountId}/contacts/custom_view/${view.id}`
            ),
          })),
      };
    },
    additionalSecondaryMenuItems() {
      let conversationMenuItems = [this.inboxSection, this.labelSection];
      let contactMenuItems = [this.contactLabelSection];
      if (this.teams.length) {
        conversationMenuItems = [this.teamSection, ...conversationMenuItems];
      }
      if (this.customViews.length) {
        conversationMenuItems = [this.foldersSection, ...conversationMenuItems];
      }
      if (this.contactCustomViews.length) {
        contactMenuItems = [this.contactSegmentsSection, ...contactMenuItems];
      }
      return {
        conversations: conversationMenuItems,
        contacts: contactMenuItems,
      };
    },
  },
  watch: {
    '$route.params.inbox_id': {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue && newValue !== oldValue) {
          this.refreshInboxStats(newValue);
        }
      },
    },
  },
  mounted() {
    this.startStatsRefreshTimer();
    
    // Aggiungiamo un listener per l'evento di cambio stato
    this.$root.$on('conversation-state-changed', this.handleConversationStateChange);
    
    // Definiamo una funzione globale per permettere l'aggiornamento da qualsiasi punto
    window.updateConversationStats = () => {
      this.forceRefreshStats();
    };
  },
  beforeDestroy() {
    this.stopStatsRefreshTimer();
    
    // Puliamo il listener per evitare memory leak
    this.$root.$off('conversation-state-changed', this.handleConversationStateChange);
    
    // Rimuoviamo la funzione globale
    if (window.updateConversationStats) {
      window.updateConversationStats = null;
    }
  },
  methods: {
    startStatsRefreshTimer() {
      this.statsRefreshTimer = setInterval(() => {
        if (this.menuConfig && this.menuConfig.parentNav === 'conversations') {
          const inboxId = this.$route.params.inbox_id;
          if (inboxId) {
            this.refreshInboxStats(inboxId);
          } else {
            this.$store.dispatch('conversationStats/get');
          }
        }
      }, 5000); // Ridotto ulteriormente da 15000 a 5000 ms per aggiornamenti quasi istantanei
    },
    stopStatsRefreshTimer() {
      if (this.statsRefreshTimer) {
        clearInterval(this.statsRefreshTimer);
        this.statsRefreshTimer = null;
      }
    },
    refreshInboxStats(inboxId) {
      if (inboxId) {
        this.$store.dispatch('conversationStats/get', { inboxId });
      }
    },
    // Nuovo metodo per aggiornamento forzato
    forceRefreshStats() {
      const inboxId = this.$route.params.inbox_id;
      if (inboxId) {
        this.refreshInboxStats(inboxId);
      } else {
        this.$store.dispatch('conversationStats/get');
      }
      // Forzare anche l'aggiornamento della navbar per i badge
      if (window.updateNavbarUnreadCount) {
        window.updateNavbarUnreadCount();
      }
    },
    // Nuovo metodo per gestire il cambio di stato delle conversazioni
    handleConversationStateChange(data) {
      if (data && data.status) {
        // Se c'è il flag "immediate", l'aggiornamento deve essere istantaneo
        if (data.immediate) {
          // Aggiorniamo immediatamente e in modo forzato
          this.forceRefreshStats();
          
          // Tentiamo di aggiornare direttamente i contatori visualizzati
          this.$nextTick(() => {
            // Cerchiamo di aggiornare i contatori nelle viste
            const inboxId = data.inbox_id || this.$route.params.inbox_id;
            
            if (inboxId) {
              // Tentiamo prima un aggiornamento ottimistico (senza chiamata API)
              const selector = `.unread-count[data-inbox-id="${inboxId}"]`;
              const countElements = document.querySelectorAll(selector);
              
              if (countElements.length) {
                countElements.forEach(el => {
                  const currentCount = parseInt(el.textContent, 10) || 0;
                  // Se stiamo segnando come "letto", decrementiamo, altrimenti incrementiamo
                  if (data.status === 'read') {
                    el.textContent = Math.max(0, currentCount - 1).toString();
                  } else if (data.status === 'unread') {
                    el.textContent = (currentCount + 1).toString();
                  }
                });
              }
              
              // Aggiorniamo anche il totale complessivo, se presente
              const totalSelector = `.total-unread-count`;
              const totalElements = document.querySelectorAll(totalSelector);
              if (totalElements.length) {
                totalElements.forEach(el => {
                  const currentTotal = parseInt(el.textContent, 10) || 0;
                  // Se stiamo segnando come "letto", decrementiamo, altrimenti incrementiamo
                  if (data.status === 'read') {
                    el.textContent = Math.max(0, currentTotal - 1).toString();
                  } else if (data.status === 'unread') {
                    el.textContent = (currentTotal + 1).toString();
                  }
                });
              }
            }
          });
        } else {
          // Comportamento normale per aggiornamenti non immediati
          this.forceRefreshStats();
        }
      }
    },
    showAddLabelPopup(data) {
      this.$emit('add-label', data);
    },
    showEditLabelPopup(data) {
      this.$emit('edit-label', data);
    },
    toggleAccountModal() {
      this.$emit('toggle-accounts');
    },
    showNewLink(featureFlag) {
      return this.isFeatureEnabledonAccount(this.accountId, featureFlag);
    },
  },
};
</script>
