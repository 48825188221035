var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('router-link',{attrs:{"to":_vm.to,"custom":"","active-class":"active"},scopedSlots:_vm._u([{key:"default",fn:function({ href, isActive, navigate }){return [_c('li',{staticClass:"font-medium h-7 my-1 hover:bg-slate-25 hover:text-bg-50 flex items-center px-2 rounded-md dark:hover:bg-slate-800",class:{
        'bg-[#F2F3F5] dark:bg-slate-800': isActive,
        'text-ellipsis overflow-hidden whitespace-nowrap max-w-full':
          _vm.shouldTruncate,
      },on:{"click":navigate}},[(_vm.labelColor)?_c('div',{staticClass:"flex items-center mr-2 cursor-pointer",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.toggleDropdown.apply(null, arguments)}}},[(_vm.children.length > 0)?_c('div',[_c('fluent-icon',{class:{
              'transform rotate-0': !_vm.isDropdownOpen,
              'transform rotate-90': _vm.isDropdownOpen,
              'ml-2': true,
            },attrs:{"icon":'chevron-right',"size":"16","title":_vm.dropdownTooltipText}})],1):_c('div',{staticClass:"w-6 h-4"})]):_vm._e(),_vm._v(" "),_c('a',{staticClass:"inline-flex text-left max-w-full w-full items-center",attrs:{"href":href}},[(_vm.icon)?_c('span',{staticClass:"inline-flex items-center justify-center w-4 rounded-sm dark:bg-slate-700 p-0.5 mr-1.5 rtl:mr-0 rtl:ml-1.5"},[_c('fluent-icon',{staticClass:"text-xxs text-slate-700 dark:text-slate-200",class:{
              'text-[#202020] dark:text-woot-500': isActive,
            },attrs:{"icon":_vm.icon,"size":"14"}})],1):_vm._e(),_vm._v(" "),(_vm.labelColor)?_c('span',{staticClass:"inline-flex rounded-sm bg h-3 w-3.5 mr-1.5 rtl:mr-0 rtl:ml-1.5 border border-slate-50 dark:border-slate-900",style:({ backgroundColor: _vm.labelColor })}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"items-center flex overflow-hidden whitespace-nowrap text-ellipsis w-full justify-between"},[_c('span',{staticClass:"text-sm text-slate-700 dark:text-slate-100",class:{
              'text-[#202020] dark:text-woot-500': isActive,
              'text-ellipsis overflow-hidden whitespace-nowrap max-w-[76px]':
                _vm.shouldTruncate,
            },attrs:{"title":_vm.menuTitle}},[_vm._v("\n            "+_vm._s(_vm.label)+"\n          ")]),_vm._v(" "),(_vm.showChildCount && _vm.childItemCount !== 0)?_c('span',{staticClass:"bg-[#FFFFFF] rounded-full w-[20px] h-[20px] flex justify-center items-center text-xs font-bold mx-1",class:_vm.isCountZero
                ? `text-slate-300 dark:text-slate-500`
                : `text-slate-700 dark:text-slate-50`},[_vm._v("\n            "+_vm._s(_vm.childItemCount)+"\n          ")]):_vm._e()]),_vm._v(" "),(_vm.warningIcon)?_c('span',{staticClass:"inline-flex bg dark:bg p-0.5 rounded-sm",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleWarningClick.apply(null, arguments)}}},[_c('fluent-icon',{directives:[{name:"tooltip",rawName:"v-tooltip.top-end",value:(_vm.$t('SIDEBAR.REAUTHORIZE')),expression:"$t('SIDEBAR.REAUTHORIZE')",modifiers:{"top-end":true}}],staticClass:"text-xxs text-red-500 dark:text-red-300",attrs:{"icon":_vm.warningIcon,"size":"16"}})],1):_vm._e()]),_vm._v(" "),(_vm.labelColor)?_c('div',[_c('context-menu',{attrs:{"context-menu-position":_vm.contextMenuPosition,"is-open":_vm.showContextMenu,"enabled-options":_vm.contextMenuEnabledOptions,"labelObject":_vm.labelObject},on:{"open":_vm.openContextMenu,"close":_vm.closeContextMenu,"add-label":_vm.showAddLabelPopup,"edit-label":_vm.showEditLabelPopup}})],1):_vm._e()])]}}])}),_vm._v(" "),(_vm.isDropdownOpen)?_c('ul',{staticClass:"mb-0 ml-12 list-none"},_vm._l((_vm.children),function(child){return _c('secondary-sub-child-nav-item',{key:child.id,attrs:{"to":child.toState,"label":child.label,"label-color":child.color,"should-truncate":child.truncateLabel,"labelObject":{ ...child, title: child.label }},on:{"edit-label":_vm.showEditLabelPopup,"sub-child-edit-label":_vm.handleSubChildEditLabel}})}),1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }