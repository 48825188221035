<template>
  <div>
    <router-link
      v-slot="{ href, isActive, navigate }"
      :to="to"
      custom
      active-class="active"
    >
      <li
        class="font-medium h-7 my-1 hover:bg-slate-25 hover:text-bg-50 flex items-center px-2 rounded-md dark:hover:bg-slate-800"
        :class="{
          'bg-[#F2F3F5] dark:bg-slate-800': isActive,
          'text-ellipsis overflow-hidden whitespace-nowrap max-w-full':
            shouldTruncate,
        }"
        @click="navigate"
      >
        <div
          v-if="labelColor"
          class="flex items-center mr-2 cursor-pointer"
          @click.stop.prevent="toggleDropdown"
        >
          <div v-if="children.length > 0">
            <fluent-icon
              :icon="'chevron-right'"
              size="16"
              :class="{
                'transform rotate-0': !isDropdownOpen,
                'transform rotate-90': isDropdownOpen,
                'ml-2': true,
              }"
              :title="dropdownTooltipText"
            />
          </div>
          <div v-else class="w-6 h-4" />
        </div>
        <a
          :href="href"
          class="inline-flex text-left max-w-full w-full items-center"
        >
          <span
            v-if="icon"
            class="inline-flex items-center justify-center w-4 rounded-sm dark:bg-slate-700 p-0.5 mr-1.5 rtl:mr-0 rtl:ml-1.5"
          >
            <fluent-icon
              class="text-xxs text-slate-700 dark:text-slate-200"
              :class="{
                'text-[#202020] dark:text-woot-500': isActive,
              }"
              :icon="icon"
              size="14"
            />
          </span>

          <span
            v-if="labelColor"
            class="inline-flex rounded-sm bg h-3 w-3.5 mr-1.5 rtl:mr-0 rtl:ml-1.5 border border-slate-50 dark:border-slate-900"
            :style="{ backgroundColor: labelColor }"
          />
          <div
            class="items-center flex overflow-hidden whitespace-nowrap text-ellipsis w-full justify-between"
          >
            <span
              :title="menuTitle"
              class="text-sm text-slate-700 dark:text-slate-100"
              :class="{
                'text-[#202020] dark:text-woot-500': isActive,
                'text-ellipsis overflow-hidden whitespace-nowrap max-w-[76px]':
                  shouldTruncate,
              }"
            >
              {{ label }}
            </span>
            <span
              v-if="showChildCount && childItemCount !== 0"
              class="bg-[#FFFFFF] rounded-full w-[20px] h-[20px] flex justify-center items-center text-xs font-bold mx-1"
              :class="
                isCountZero
                  ? `text-slate-300 dark:text-slate-500`
                  : `text-slate-700 dark:text-slate-50`
              "
            >
              {{ childItemCount }}
            </span>
          </div>
          <span
            v-if="warningIcon"
            class="inline-flex bg dark:bg p-0.5 rounded-sm"
            @click.stop.prevent="handleWarningClick"
          >
            <fluent-icon
              v-tooltip.top-end="$t('SIDEBAR.REAUTHORIZE')"
              class="text-xxs text-red-500 dark:text-red-300"
              :icon="warningIcon"
              size="16"
            />
          </span>
        </a>
        <div v-if="labelColor">
          <context-menu
            :context-menu-position="contextMenuPosition"
            :is-open="showContextMenu"
            :enabled-options="contextMenuEnabledOptions"
            :labelObject="labelObject"
            @open="openContextMenu"
            @close="closeContextMenu"
            @add-label="showAddLabelPopup"
            @edit-label="showEditLabelPopup"
          />
        </div>
      </li>
    </router-link>
    <ul v-if="isDropdownOpen" class="mb-0 ml-12 list-none">
      <secondary-sub-child-nav-item
        v-for="child in children"
        :key="child.id"
        :to="child.toState"
        :label="child.label"
        :label-color="child.color"
        :should-truncate="child.truncateLabel"
        :labelObject="{ ...child, title: child.label }"
        @edit-label="showEditLabelPopup"
        @sub-child-edit-label="handleSubChildEditLabel"
      />
    </ul>
  </div>
</template>
<script>
import ContextMenu from 'dashboard/modules/labels/components/LabelContextMenu.vue';
import { mapGetters } from 'vuex';
import SecondarySubChildNavItem from './SecondarySubChildNavItem.vue';

export default {
  components: { SecondarySubChildNavItem, ContextMenu },
  props: {
    to: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    labelColor: {
      type: String,
      default: '',
    },
    children: {
      type: Array,
      default: () => [],
    },
    shouldTruncate: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    warningIcon: {
      type: String,
      default: '',
    },
    showChildCount: {
      type: Boolean,
      default: false,
    },
    childItemCount: {
      type: Number,
      default: 0,
    },
    labelObject: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isDropdownOpen: false,
      isContextMenuOpen: false,
      contextMenuPosition: {},
      showContextMenu: false,
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
    }),
    dropdownTooltipText() {
      return this.isDropdownOpen ? 'Chiudi menu' : 'Apri menu';
    },
    showIcon() {
      return {
        'overflow-hidden whitespace-nowrap text-ellipsis': this.shouldTruncate,
      };
    },
    isCountZero() {
      return this.childItemCount === 0;
    },
    menuTitle() {
      return this.shouldTruncate ? this.label : '';
    },
    contextMenuEnabledOptions() {
      return {
        create: true,
        edit: true,
        delete: true,
      };
    },
  },
  methods: {
    showAddLabelPopup() {
      this.$emit('add-label', {
        isRoot: false,
        labelObject: this.labelObject,
      });
    },
    showEditLabelPopup() {
      this.$emit('edit-label', { labelObject: this.labelObject });
    },
    handleSubChildEditLabel(data) {
      this.$emit('edit-label', data);
    },
    handleWarningClick() {
      this.$router.push(
        `/app/accounts/${this.accountId}/settings/inboxes/list`
      );
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    toggleContextMenu() {
      this.isContextMenuOpen = !this.isContextMenuOpen;
    },
    openContextMenu(e) {
      e.preventDefault();

      this.contextMenuPosition = {
        x: e.pageX || e.clientX,
        y: e.pageY || e.clientY,
      };
      this.showContextMenu = true;
    },
    closeContextMenu() {
      this.showContextMenu = false;
      this.contextMenuPosition = { x: null, y: null };
    },
  },
};
</script>
<style lang="scss">
li.right .context-menu-wrap {
  @apply ml-auto;
}
</style>
