import { throwErrorMessage } from 'dashboard/store/utils/api';
import ConversationApi from '../../../../api/inbox/conversation';
import mutationTypes from '../../../mutation-types';

// Definizione delle costanti di stato
const CONVERSATION_STATUS = {
  READ: 'read',
  UNREAD: 'unread'
};

export default {
  markMessagesRead: async ({ commit, dispatch }, data) => {
    try {
      const {
        data: { id, agent_last_seen_at: lastSeen },
      } = await ConversationApi.markMessageRead(data);
      setTimeout(
        () =>
          commit(mutationTypes.UPDATE_MESSAGE_UNREAD_COUNT, { id, lastSeen }),
        4000
      );
      
      // Aggiorniamo subito le statistiche per riflettere il cambio di stato
      dispatch('conversationStats/get', {}, { root: true });
    } catch (error) {
      // Handle error
    }
  },

  markMessagesUnread: async ({ commit, dispatch }, { id }) => {
    try {
      // Chiamiamo l'API ma non aggiorniamo lo stato se la risposta conferma il nostro stato ottimistico
      const {
        data: { agent_last_seen_at: lastSeen, unread_count: unreadCount },
      } = await ConversationApi.markMessagesUnread({ id });
      
      // Aggiorniamo lo stato solo se è diverso da quello ottimistico
      if (lastSeen !== null || unreadCount < 1) {
        commit(mutationTypes.UPDATE_MESSAGE_UNREAD_COUNT, {
          id,
          lastSeen: null, // Forziamo lastSeen a null per mantenere lo stato non letto
          unreadCount: Math.max(unreadCount, 1), // Assicuriamoci che sia almeno 1
        });
      }
      
      // Aggiorniamo le statistiche globali
      dispatch('conversationStats/get', {}, { root: true });
    } catch (error) {
      throwErrorMessage(error);
    }
  },

  toggleRead: async ({ commit, dispatch }, { id, lastSeen }) => {
    try {
      await ConversationApi.toggleStatus({
        conversationId: id,
        status: CONVERSATION_STATUS.READ,
        lastSeen,
      });
      commit(mutationTypes.UPDATE_MESSAGE_UNREAD_COUNT, { id, lastSeen });
      
      // Aggiorniamo subito le statistiche per riflettere il cambio di stato
      dispatch('conversationStats/get', {}, { root: true });
    } catch (error) {
      // Handle error
    }
  },

  toggleUnread: async ({ commit, dispatch }, { id }) => {
    try {
      console.log(`[TOGGLE_UNREAD] Avvio operazione - Conversation #${id}`, {
        timestamp: new Date().toISOString()
      });
      
      // Prima chiamiamo l'API per segnare la conversazione come non letta
      console.log(`[TOGGLE_UNREAD] Chiamata API toggleStatus - UNREAD`);
      await ConversationApi.toggleStatus({
        conversationId: id,
        status: CONVERSATION_STATUS.UNREAD,
      });
      console.log(`[TOGGLE_UNREAD] Chiamata API completata con successo`);
      
      // Dopo il successo dell'API, forziamo un aggiornamento della conversazione
      // per ottenere i dati più recenti inclusi tutti gli stati
      console.log(`[TOGGLE_UNREAD] Richiesta dati aggiornati della conversazione`);
      const conversation = await ConversationApi.show(id);
      console.log(`[TOGGLE_UNREAD] Dati ricevuti:`, conversation.data);
      
      // Ci assicuriamo che lo stato unread_count sia almeno 1
      const unreadCount = Math.max(conversation.data.unread_count || 0, 1);
      console.log(`[TOGGLE_UNREAD] Conteggio non letti calcolato: ${unreadCount}`);
      
      // Aggiorniamo lo stato locale della conversazione sia per il conteggio che per altri attributi
      console.log(`[TOGGLE_UNREAD] Commit mutation UPDATE_MESSAGE_UNREAD_COUNT`);
      commit(mutationTypes.UPDATE_MESSAGE_UNREAD_COUNT, { 
        id, 
        unreadCount,
        // Impostiamo lastSeen a null per coerenza dello stato di non letto
        lastSeen: null 
      });
      
      // Aggiorniamo completamente la conversazione per assicurarci che tutti gli stati siano coerenti
      console.log(`[TOGGLE_UNREAD] Commit mutation updateConversation`);
      commit('updateConversation', conversation.data);
      
      // Aggiorniamo le statistiche globali
      console.log(`[TOGGLE_UNREAD] Dispatch conversationStats/get`);
      dispatch('conversationStats/get', {}, { root: true });
      
      // Attendiamo un momento e poi forziamo un aggiornamento dell'elenco delle conversazioni
      console.log(`[TOGGLE_UNREAD] Scheduling fetchAllConversations (500ms)`);
      setTimeout(() => {
        console.log(`[TOGGLE_UNREAD] Dispatch fetchAllConversations`);
        dispatch('fetchAllConversations', {}, { root: true });
      }, 500);
      
      // Logging finale
      console.log(`[TOGGLE_UNREAD] Operazione completata con successo per #${id}`);
      
      return { success: true, data: conversation.data };
    } catch (error) {
      // Log dettagliato dell'errore per debug
      console.error(`[TOGGLE_UNREAD] Errore durante la marcatura della conversazione #${id} come non letta:`, error);
      console.error(`[TOGGLE_UNREAD] Stack trace:`, error.stack);
      
      if (error.response) {
        console.error(`[TOGGLE_UNREAD] Risposta API:`, {
          status: error.response.status,
          statusText: error.response.statusText,
          data: error.response.data
        });
      }
      
      return { success: false, error };
    }
  },
};
