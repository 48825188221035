import Vue from 'vue';
import EngineerThreadsAPI from '../../api/engineerThreads';

export const state = {
  records: {},
  uiFlags: {
    isFetchingThread: false,
    isFetchingMessages: false,
    isFetchingFreeThread: false,
  },
  currentThreadId: null,
  messages: [],
  freeThread: {
    data: null,
    messages: [],
  },
  conversationThread: {
    data: null,
    messages: [],
  },
};

export const getters = {
  getThreadByConversationId: (_state) => (conversationId) => {
    return Object.values(_state.records).find(
      (thread) => thread.conversation_id === conversationId
    );
  },
  getCurrentThread: (_state) => _state.records[_state.currentThreadId] || null,
  getThreadMessages: (_state) => {
    // Se siamo in un thread di conversazione, restituiamo i messaggi della conversazione
    if (
      _state.currentThreadId &&
      _state.records[_state.currentThreadId]?.thread_type === 'conversation'
    ) {
      return _state.conversationThread.messages;
    }
    // Altrimenti restituiamo i messaggi del thread libero
    return _state.freeThread.messages;
  },
  getFreeThread: (_state) => _state.freeThread.data,
  getFreeThreadMessages: (_state) => _state.freeThread.messages,
  getConversationThread: (_state) => _state.conversationThread.data,
  getConversationThreadMessages: (_state) => _state.conversationThread.messages,
  getUIFlags: (_state) => _state.uiFlags,
};

export const actions = {
  async fetch({ commit, state }, { conversationId, signal }) {
    if (!conversationId) {
      console.error('❌ [engineerThreads/fetch] conversationId mancante');
      return null;
    }

    // Forziamo il reset del flag se è rimasto bloccato
    if (state.uiFlags.isFetchingThread) {
      console.warn('⚠️ [engineerThreads/fetch] Reset flag fetching bloccato');
      commit('SET_UI_FLAG', {
        isFetchingThread: false,
      });
    }

    // Verifichiamo se stiamo già gestendo questo thread
    if (state.currentThreadId) {
      const currentThread = state.records[state.currentThreadId];
      if (currentThread?.conversation_id === conversationId) {
        return currentThread;
      }
    }

    commit('SET_UI_FLAG', {
      isFetchingThread: true,
    });

    try {
      // Puliamo subito l'UI quando entriamo in una conversazione
      commit('CLEAR_CONVERSATION_THREAD');
      commit('SET_CURRENT_THREAD_ID', null);

      const response =
        await EngineerThreadsAPI.getByConversationId(conversationId);

      if (!response) {
        throw new Error('Risposta API vuota');
      }

      const thread = Array.isArray(response.data)
        ? response.data[0]
        : response.data;

      if (thread) {
        await commit('SET_THREAD', thread);
        await commit('SET_CURRENT_THREAD_ID', thread.id);
        return thread;
      }

      return null;
    } catch (error) {
      console.error('❌ [engineerThreads/fetch] Errore:', error);
      return null;
    } finally {
      commit('SET_UI_FLAG', {
        isFetchingThread: false,
      });
    }
  },

  async fetchMessages({ commit, state }, { threadId }) {
    if (state.uiFlags.isFetchingMessages) {
      return;
    }

    if (!threadId) {
      console.error('❌ [engineerThreads/fetchMessages] ThreadId mancante');
      return;
    }

    commit('SET_UI_FLAG', {
      isFetchingMessages: true,
    });

    try {
      const response = await EngineerThreadsAPI.getMessages(threadId);
      const messages = response.data;

      // Determiniamo il tipo di thread
      const thread = state.records[threadId];
      const threadType = thread?.thread_type || 'free';

      commit('SET_MESSAGES', { messages, threadType });
      return messages;
    } catch (error) {
      console.error('❌ [engineerThreads/fetchMessages] Errore:', error);
      return [];
    } finally {
      commit('SET_UI_FLAG', {
        isFetchingMessages: false,
      });
    }
  },

  async createOrGetFreeThread({ commit, state }) {
    if (state.uiFlags.isFetchingFreeThread) {
      return;
    }

    commit('SET_UI_FLAG', {
      isFetchingFreeThread: true,
    });

    let thread = null;

    try {
      const response = await EngineerThreadsAPI.getFreeThread();
      if (response.data) {
        thread = response.data;
      }
    } catch (error) {
      // Se è 404 proviamo a creare un nuovo thread
      if (error.response?.status === 404) {
        try {
          const newThread = await EngineerThreadsAPI.createFreeThread();
          thread = newThread.data;
        } catch (createError) {
          console.error(
            '❌ [engineerThreads/createOrGetFreeThread] Errore nella creazione:',
            createError
          );
          throw createError;
        }
      } else {
        console.error(
          '❌ [engineerThreads/createOrGetFreeThread] Errore nel recupero:',
          error
        );
        throw error;
      }
    } finally {
      // Resettiamo sempre il flag alla fine
      commit('SET_UI_FLAG', {
        isFetchingFreeThread: false,
      });
    }

    // Se abbiamo un thread (esistente o nuovo), lo settiamo
    if (thread) {
      commit('SET_THREAD', thread);
      commit('SET_CURRENT_THREAD_ID', thread.id);
      return thread;
    }

    return null;
  },

  async createConversationThread({ commit }, { conversationId }) {
    if (!conversationId) {
      console.error(
        '❌ [engineerThreads/createConversationThread] conversationId mancante'
      );
      throw new Error(
        'conversationId è richiesto per creare un thread di conversazione'
      );
    }

    try {
      const response =
        await EngineerThreadsAPI.createConversationThread(conversationId);

      if (!response || !response.data) {
        throw new Error(
          '❌ [engineerThreads/createConversationThread] Risposta API vuota o non valida'
        );
      }

      const thread = response.data;

      commit('SET_THREAD', thread);
      commit('SET_CURRENT_THREAD_ID', thread.id);
      return thread;
    } catch (error) {
      console.error(
        '❌ [engineerThreads/createConversationThread] Errore:',
        error
      );
      throw new Error('Impossibile creare il thread di conversazione');
    }
  },

  async createMessage(
    { commit, state, dispatch },
    { threadId, content, role = 'user', conversationId }
  ) {
    try {
      if (conversationId && !threadId) {
        const newThread =
          await EngineerThreadsAPI.createConversationThread(conversationId);
        threadId = newThread.data.id;
        commit('SET_THREAD', newThread.data);
        commit('SET_CURRENT_THREAD_ID', newThread.data.id);
      }

      const response = await EngineerThreadsAPI.createMessage(threadId, {
        thread_message: {
          role,
          content,
        },
      });

      const message = response.data;

      // Determiniamo il tipo di thread
      const thread = state.records[threadId];
      const threadType = thread?.thread_type || 'free';

      commit('ADD_MESSAGE', { message, threadType });
      return message;
    } catch (error) {
      console.error('❌ [engineerThreads/createMessage] Errore:', error);
      throw error;
    }
  },

  clearCurrentThread({ commit }) {
    commit('SET_CURRENT_THREAD_ID', null);
    commit('CLEAR_FREE_THREAD');
    commit('CLEAR_CONVERSATION_THREAD');
  },

  async archiveThread({ commit, state, dispatch }) {
    const threadId = state.currentThreadId;

    if (!threadId) {
      return;
    }

    try {
      // Salviamo il tipo di thread prima di archiviarlo
      const threadType = state.records[threadId]?.thread_type;

      await EngineerThreadsAPI.update(threadId, {
        is_active: false,
        is_deleted: true,
      });

      // Reset dello stato
      commit('CLEAR_FREE_THREAD');
      commit('CLEAR_CONVERSATION_THREAD');
      commit('SET_CURRENT_THREAD_ID', null);

      // Se era un thread libero, ne creiamo subito uno nuovo
      if (threadType === 'free') {
        await dispatch('createOrGetFreeThread');
      }

      return true;
    } catch (error) {
      console.error('❌ [engineerThreads/archiveThread] Errore:', error);
      throw error;
    }
  },

  async switchToFreeThread() {
    try {
      // Non puliamo qui lo stato perché lo fa già il watcher
      const thread = await this.$store.dispatch(
        'engineerThreads/createOrGetFreeThread'
      );

      if (thread) {
        await this.$store.dispatch('engineerThreads/fetchMessages', {
          threadId: thread.id,
        });
      }
    } catch (error) {
      console.error(
        '❌ [RightSidebar] Errore nel recupero/creazione thread libero:',
        error
      );
    }
  },
};

export const mutations = {
  SET_THREAD(_state, thread) {
    try {
      const records = {};
      records[thread.id] = thread;
      Vue.set(_state, 'records', records);

      // Aggiorniamo anche il thread specifico in base al tipo
      if (thread.thread_type === 'free') {
        Vue.set(_state.freeThread, 'data', thread);
      } else if (thread.thread_type === 'conversation') {
        Vue.set(_state.conversationThread, 'data', thread);
      }
    } catch (error) {
      console.error('❌ [mutation/SET_THREAD] Errore:', error);
      throw error;
    }
  },

  ADD_THREAD(_state, thread) {
    Vue.set(_state.records, thread.id, thread);

    // Aggiorniamo anche il thread specifico in base al tipo
    if (thread.thread_type === 'free') {
      Vue.set(_state.freeThread, 'data', thread);
    } else if (thread.thread_type === 'conversation') {
      Vue.set(_state.conversationThread, 'data', thread);
    }
  },

  SET_CURRENT_THREAD_ID(_state, threadId) {
    try {
      _state.currentThreadId = threadId;
    } catch (error) {
      console.error('❌ [mutation/SET_CURRENT_THREAD_ID] Errore:', error);
      throw error;
    }
  },

  SET_MESSAGES(_state, { messages, threadType }) {
    try {
      if (threadType === 'free') {
        Vue.set(_state.freeThread, 'messages', messages || []);
      } else if (threadType === 'conversation') {
        Vue.set(_state.conversationThread, 'messages', messages || []);
      }
    } catch (error) {
      console.error('❌ [mutation/SET_MESSAGES] Errore:', error);
      throw error;
    }
  },

  ADD_MESSAGE(_state, { message, threadType }) {
    if (threadType === 'free') {
      if (!Array.isArray(_state.freeThread.messages)) {
        Vue.set(_state.freeThread, 'messages', []);
      }
      _state.freeThread.messages.push(message);
    } else if (threadType === 'conversation') {
      if (!Array.isArray(_state.conversationThread.messages)) {
        Vue.set(_state.conversationThread, 'messages', []);
      }
      _state.conversationThread.messages.push(message);
    }
  },

  CLEAR_FREE_THREAD(_state) {
    Vue.set(_state.freeThread, 'data', null);
    Vue.set(_state.freeThread, 'messages', []);
  },

  CLEAR_CONVERSATION_THREAD(_state) {
    Vue.set(_state.conversationThread, 'data', null);
    Vue.set(_state.conversationThread, 'messages', []);
  },

  SET_UI_FLAG(_state, flags) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...flags,
    };
  },

  SET_THREAD_MESSAGES(_state, messages) {
    // Determiniamo il tipo di thread corrente
    const currentThread = _state.records[_state.currentThreadId];
    const threadType = currentThread?.thread_type || 'free';

    // Aggiorniamo i messaggi nel posto giusto
    if (threadType === 'conversation') {
      _state.conversationThread.messages = messages;
    } else {
      _state.freeThread.messages = messages;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
