const state = {
  selectedAIContact: null,
  showRightSidebar: localStorage.getItem('showRightSidebar') !== 'false',
};

const mutations = {
  SET_AI_CONTACT(state, contact) {
    state.selectedAIContact = contact;
  },
  TOGGLE_RIGHT_SIDEBAR(state) {
    state.showRightSidebar = !state.showRightSidebar;
    localStorage.setItem('showRightSidebar', state.showRightSidebar);
  },
};

const actions = {
  setAIContact({ commit }, contact) {
    commit('SET_AI_CONTACT', contact);
  },
  toggleRightSidebar({ commit }) {
    commit('TOGGLE_RIGHT_SIDEBAR');
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
