<template>
  <li v-show="isMenuItemVisible" class="mt-1">
    <div v-if="hasSubMenu" class="flex items-center">
      <div
        v-if="shouldShowIcon"
        class="flex items-center mr-2 cursor-pointer"
        @click="toggleDropdownMenu"
      >
        <fluent-icon
          :icon="'chevron-right'"
          size="16"
          :class="{
            'transform rotate-0': !isDropdownOpen,
            'transform rotate-90': isDropdownOpen,
            'mt-1.5 ml-2': true,
          }"
          :title="dropdownTooltipText"
        />
      </div>
      <span
        v-if="shouldShowLabel"
        class="pt-1 my-2 text-sm font-semibold text-slate-700 dark:text-slate-200 flex-grow"
      >
        {{ $t(`SIDEBAR.${menuItem.label}`) }}
      </span>

      <div v-if="menuItem.showNewButton" class="flex items-center">
        <woot-button
          size="tiny"
          variant="clear"
          color-scheme="secondary"
          icon="add"
          class="p-0 ml-2"
          @click="onClickOpen"
        />
      </div>
    </div>
    <router-link
      v-else
      :ref="isLabelType ? 'labelLink' : undefined"
      class="flex items-center p-2 m-0 text-sm font-medium leading-4 rounded-lg text-slate-700 dark:text-slate-100 hover:bg-slate-25 dark:hover:bg-slate-800 drop-target"
      :class="[computedClass, { 'drag-over': isDragOver && isLabelType }]"
      :to="menuItem && menuItem.toState"
      @dragover="onDragOver"
      @dragleave="onDragLeave"
      @drop="onDrop"
      @custom-drop="onCustomDrop"
      @mouseenter="onMouseEnter"
      @mouseleave="onMouseLeave"
    >
      <fluent-icon
        :icon="menuItem.icon"
        class="min-w-[1rem] mr-1.5 rtl:mr-0 rtl:ml-1.5"
        size="14"
      />
      <span v-if="isLabelType" class="drop-target-span">
        {{ $t(`SIDEBAR.${menuItem.label}`) }}
      </span>
      <span v-else>
        {{ $t(`SIDEBAR.${menuItem.label}`) }}
      </span>
      <span
        v-if="showChildCount(menuItem.count) && menuItem.count !== 0"
        class="px-1 py-0 mx-1 font-medium rounded-md text-xxs"
        :class="{
          'text-slate-300 dark:text-slate-600': isCountZero && !isActiveView,
          'text-slate-600 dark:text-slate-50': !isCountZero && !isActiveView,
          'bg-[#D1D0CA] dark:bg-woot-200 text-[#202020] dark:text-woot-600':
            isActiveView,
          'bg-slate-50 dark:bg-slate-700': !isActiveView,
        }"
      >
        {{ `${menuItem.count}` }}
      </span>
      <span
        v-if="menuItem.beta"
        data-view-component="true"
        label="Beta"
        class="inline-block px-1 mx-1 font-medium leading-4 text-green-500 border border-green-400 rounded-lg text-xxs"
      >
        {{ $t('SIDEBAR.BETA') }}
      </span>
    </router-link>

    <transition name="slide">
      <ul v-if="isDropdownOpen && hasSubMenu" class="mb-0 ml-0 list-none">
        <secondary-child-nav-item
          v-for="child in filteredChildren"
          :key="child.id"
          :labelObject="{ ...child, title: child.label }"
          :children="child.children"
          :to="child.toState"
          :label="child.label"
          :label-color="child.color"
          :should-truncate="child.truncateLabel"
          :icon="computedInboxClass(child)"
          :warning-icon="computedInboxErrorClass(child)"
          :show-child-count="true"
          :child-item-count="getUnreadCountByInbox(child.id)"
          @add-label="showAddLabelPopup"
          @edit-label="showEditLabelPopup"
        />
        <router-link
          v-if="showItem(menuItem)"
          v-slot="{ href, navigate }"
          :to="menuItem.toState"
          custom
        >
          <li class="pl-1">
            <a :href="href">
              <woot-button
                size="tiny"
                variant="clear"
                color-scheme="secondary"
                icon="add"
                :data-testid="menuItem.dataTestid"
                @click="(e) => newLinkClick(e, navigate)"
              >
                {{ $t(`SIDEBAR.${menuItem.newLinkTag}`) }}
              </woot-button>
            </a>
          </li>
        </router-link>
      </ul>
    </transition>
  </li>
</template>

<script>
import { mapGetters } from 'vuex';

import {
  getInboxClassByType,
  getInboxWarningIconClass,
} from 'dashboard/helper/inbox';
import configMixin from 'shared/mixins/configMixin';
import adminMixin from '../../../mixins/isAdmin';

import {
  isOnMentionsView,
  isOnUnattendedView,
} from '../../../store/modules/conversations/helpers/actionHelpers';
import SecondaryChildNavItem from './SecondaryChildNavItem.vue';

export default {
  components: { SecondaryChildNavItem },
  mixins: [adminMixin, configMixin],
  props: {
    menuItem: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isDropdownOpen: true,
      isDragOver: false,
    };
  },
  computed: {
    dropdownTooltipText() {
      return this.isDropdownOpen ? 'Chiudi menu' : 'Apri menu';
    },
    filteredChildren() {
      if (this.menuItem.label === 'CUSTOM_VIEWS_FOLDER') {
        return this.menuItem.children.filter(
          (child) => child.newLinkTag === 'CUSTOM_VIEWS_FOLDER'
        );
      }
      return this.menuItem.children;
    },
    shouldShowLabel() {
      // Mostra l'etichetta solo se non è "Canali di comunicazione!"
      return this.menuItem.label !== 'INBOXES';
    },
    shouldShowIcon() {
      return (
        this.menuItem.label !== 'INBOXES' &&
        this.hasSubMenu &&
        !this.menuItem.showNewButton
      );
    },
    ...mapGetters({
      activeInbox: 'getSelectedInbox',
      accountId: 'getCurrentAccountId',
      isFeatureEnabledonAccount: 'accounts/isFeatureEnabledonAccount',
      globalConfig: 'globalConfig/get',
      getUnreadCountByInbox: 'conversationStats/getUnreadCountByInbox',
    }),
    isCountZero() {
      return this.menuItem.count === 0;
    },
    isActiveView() {
      return this.computedClass.includes('active-view');
    },
    hasSubMenu() {
      const has = !!this.menuItem.children;
      return has;
    },
    isMenuItemVisible() {
      if (this.menuItem.globalConfigFlag) {
        // this checks for the `csmlEditorHost` flag in the global config
        // if this is present, we toggle the CSML editor menu item
        // TODO: This is very specific, and can be handled better, fix it
        return !!this.globalConfig[this.menuItem.globalConfigFlag];
      }

      let isFeatureEnabled = true;
      if (this.menuItem.featureFlag) {
        isFeatureEnabled = this.isFeatureEnabledonAccount(
          this.accountId,
          this.menuItem.featureFlag
        );
      }

      if (this.menuItem.isEnterpriseOnly) {
        if (!this.isEnterprise) return false;
        return isFeatureEnabled || this.globalConfig.displayManifest;
      }

      if (this.menuItem.featureFlag) {
        return this.isFeatureEnabledonAccount(
          this.accountId,
          this.menuItem.featureFlag
        );
      }

      return isFeatureEnabled;
    },
    isAllConversations() {
      return (
        this.$store.state.route.name === 'inbox_conversation' &&
        this.menuItem.toStateName === 'home'
      );
    },
    isMentions() {
      return (
        isOnMentionsView({ route: this.$route }) &&
        this.menuItem.toStateName === 'conversation_mentions'
      );
    },
    isUnattended() {
      return (
        isOnUnattendedView({ route: this.$route }) &&
        this.menuItem.toStateName === 'conversation_unattended'
      );
    },
    isTeamsSettings() {
      return (
        this.$store.state.route.name === 'settings_teams_edit' &&
        this.menuItem.toStateName === 'settings_teams_list'
      );
    },
    isInboxSettings() {
      return (
        this.$store.state.route.name === 'settings_inbox_show' &&
        this.menuItem.toStateName === 'settings_inbox_list'
      );
    },
    isIntegrationsSettings() {
      return (
        this.$store.state.route.name === 'settings_integrations_webhook' &&
        this.menuItem.toStateName === 'settings_integrations'
      );
    },
    isApplicationsSettings() {
      return (
        this.$store.state.route.name === 'settings_applications_integration' &&
        this.menuItem.toStateName === 'settings_applications'
      );
    },
    isCurrentRoute() {
      return this.$store.state.route.name.includes(this.menuItem.toStateName);
    },

    computedClass() {
      // If active inbox is present, do not highlight conversations
      if (this.activeInbox) return ' ';
      if (
        this.isAllConversations ||
        this.isMentions ||
        this.isUnattended ||
        this.isCurrentRoute
        //CRI text-[#202020]
      ) {
        return 'bg-[#F2F3F5] dark:bg-slate-800 text-[#202020] dark:text-woot-500 hover:text-[#202020] dark:hover:text-woot-500 active-view';
      }
      if (this.hasSubMenu) {
        if (
          this.isTeamsSettings ||
          this.isInboxSettings ||
          this.isIntegrationsSettings ||
          this.isApplicationsSettings
        ) {
          return 'bg-[#D1D0CA] dark:bg-slate-800 text-[#202020] dark:text-woot-500 hover:text-woot-700 dark:hover:text-woot-500 active-view';
        }
        return 'hover:text-slate-700 dark:hover:text-slate-100';
      }

      return 'hover:text-slate-700 dark:hover:text-slate-100';
    },
    isLabelType() {
      // Verifica se questo elemento di menu è un'etichetta
      return (
        this.menuItem.icon === 'tag' ||
        (this.menuItem.toStateName &&
          this.menuItem.toStateName.includes('label'))
      );
    },
  },
  mounted() {
    // Registra un listener per l'evento custom-drop
    if (this.$el && this.isLabelType) {
      this.$el.addEventListener('custom-drop', this.onCustomDrop);
    }

    // Aggiungiamo anche un listener globale per catturare l'evento alternativo
    if (this.isLabelType) {
      document.addEventListener(
        'conversation-label-drop',
        this.onGlobalLabelDrop
      );
    }
  },
  beforeDestroy() {
    // Rimuovi il listener quando il componente viene distrutto
    if (this.$el && this.isLabelType) {
      this.$el.removeEventListener('custom-drop', this.onCustomDrop);
    }

    // Rimuovi anche il listener globale
    if (this.isLabelType) {
      document.removeEventListener(
        'conversation-label-drop',
        this.onGlobalLabelDrop
      );
    }
  },
  methods: {
    showAddLabelPopup(data) {
      if (!data) {
        this.$emit('add-label', { isRoot: true });
        return;
      }

      this.$emit('add-label', {
        isRoot: false,
        labelObject: data.labelObject,
      });
    },
    showEditLabelPopup(data) {
      this.$emit('edit-label', data);
    },
    toggleDropdownMenu() {
      this.isDropdownOpen = !this.isDropdownOpen;
      this.$forceUpdate();
    },
    computedInboxClass(child) {
      const { type, phoneNumber } = child;
      if (!type) return '';
      const classByType = getInboxClassByType(type, phoneNumber);
      return classByType;
    },
    computedInboxErrorClass(child) {
      const { type, reauthorizationRequired } = child;
      if (!type) return '';
      const warningClass = getInboxWarningIconClass(
        type,
        reauthorizationRequired
      );
      return warningClass;
    },
    newLinkClick(e, navigate) {
      if (this.menuItem.newLinkRouteName) {
        navigate(e);
      } else if (this.menuItem.showModalForNewItem) {
        if (this.menuItem.modalName === 'AddLabel') {
          e.preventDefault();
          this.$emit('add-label', { isRoot: true });
        }
      }
    },
    showItem(item) {
      return this.isAdmin && !!item.newLink;
    },
    onClickOpen() {
      this.$emit('open');
    },
    showChildCount(count) {
      return Number.isInteger(count);
    },
    onMouseEnter() {
      if (document.querySelector('.drag-feedback-popup')) {
        this.isDragOver = true;
      }
    },
    onMouseLeave() {
      this.isDragOver = false;
    },
    onDragOver(event) {
      // Necessario per consentire il drop
      if (!this.isLabelType) return;

      event.preventDefault();

      // Verifico che si stia trascinando una conversazione
      if (event.dataTransfer.types.includes('conversationId')) {
        this.isDragOver = true;
      }
    },
    onDragLeave() {
      this.isDragOver = false;
    },
    onCustomDrop(event) {
      // Gestione dell'evento custom
      const {
        conversationId,
        conversationTitle,
        labelId: eventLabelId,
      } = event.detail;

      if (!conversationId) {
        console.error('ID conversazione mancante');
        return;
      }

      // Recupero l'ID dell'etichetta
      let labelId = eventLabelId;

      // Se non abbiamo già l'ID dall'evento, proviamo a ottenerlo dal menuItem
      if (!labelId) {
        labelId = this.getLabelIdFromMenuItem();
      }

      if (!labelId) {
        console.error("Non è stato possibile ricavare l'ID dell'etichetta");
        return;
      }

      // Mostro una notifica di conferma
      const labelName = this.$t(`SIDEBAR.${this.menuItem.label}`);

      this.$store.dispatch('notifications/show', {
        type: 'success',
        message: `Conversazione spostata in ${labelName}`,
      });

      // Assegno l'etichetta alla conversazione
      this.assignLabelToConversation(
        parseInt(conversationId, 10),
        parseInt(labelId, 10)
      );

      this.isDragOver = false;
    },
    onDrop(event) {
      if (!this.isLabelType) return;

      event.preventDefault();
      this.isDragOver = false;

      // Recupero l'ID della conversazione trascinata
      const conversationId = event.dataTransfer.getData('conversationId');
      if (!conversationId) return;

      // Recupero l'ID dell'etichetta
      const labelId = this.getLabelIdFromMenuItem();

      if (!labelId) {
        console.error("Non è stato possibile ricavare l'ID dell'etichetta");
        return;
      }

      // Mostro una notifica di conferma
      this.$store.dispatch('notifications/show', {
        type: 'success',
        message: `Conversazione spostata in ${this.$t(
          `SIDEBAR.${this.menuItem.label}`
        )}`,
      });

      // Assegno l'etichetta alla conversazione
      this.assignLabelToConversation(
        parseInt(conversationId, 10),
        parseInt(labelId, 10)
      );
    },
    getLabelIdFromMenuItem() {
      // Estrai l'ID dell'etichetta dall'URL di stato
      if (this.menuItem.id) {
        return this.menuItem.id;
      }

      // Caso degli elementi di menu etichetta standard
      if (this.menuItem.frontendURL) {
        const matches = this.menuItem.frontendURL.match(/\/labels\/(\d+)/);
        if (matches && matches[1]) {
          return matches[1];
        }
      }

      // Prova con toState se esiste
      if (this.menuItem.toState && typeof this.menuItem.toState === 'string') {
        // Se l'URL è nel formato /accounts/{accountId}/labels/{labelId}
        const matches = this.menuItem.toState.match(/labels\/(\d+)/);
        if (matches && matches[1]) {
          return matches[1];
        }
      }

      // Se il menuItem ha un attributo value, questo potrebbe essere l'ID
      if (this.menuItem.value) {
        return this.menuItem.value;
      }

      // Se il menuItem ha il nome che contiene l'ID
      if (
        this.menuItem.name &&
        typeof this.menuItem.name === 'string' &&
        this.menuItem.name.match(/\d+/)
      ) {
        const matches = this.menuItem.name.match(/\d+/);
        if (matches && matches[0]) {
          return matches[0];
        }
      }

      console.error('Impossibile trovare ID etichetta');
      return null;
    },
    assignLabelToConversation(conversationId, labelId) {
      // Qui dovremmo recuperare le etichette esistenti e aggiungere la nuova
      this.$store
        .dispatch('conversationLabels/update', {
          conversationId,
          labels: [labelId],
        })
        .then((response) => {
          // Refresh della lista delle conversazioni se necessario
          this.$store.dispatch('fetchAllConversations');
        })
        .catch((error) => {
          console.error("Errore nell'assegnazione dell'etichetta:", error);
          this.$store.dispatch('notifications/show', {
            type: 'error',
            message: this.$t('CONVERSATION.LABELS.ASSIGNMENT_FAILED'),
          });
        });
    },
    /**
     * Gestisce l'evento drop globale per assegnazione diretta
     */
    onGlobalLabelDrop(event) {
      const { conversationId, conversationTitle, labelId, targetElement } =
        event.detail;

      // Verifica se questo è l'elemento target corretto
      if (
        targetElement &&
        !targetElement.contains(this.$el) &&
        !this.$el.contains(targetElement)
      ) {
        return;
      }

      // Recupera l'ID label se non fornito
      let finalLabelId = labelId;
      if (!finalLabelId) {
        finalLabelId = this.getLabelIdFromMenuItem();
      }

      if (!conversationId || !finalLabelId) {
        console.error('Dati mancanti per assegnazione globale', {
          conversationId,
          finalLabelId,
        });
        return;
      }

      // Mostro una notifica di conferma
      const labelName = this.$t(`SIDEBAR.${this.menuItem.label}`);
      this.$store.dispatch('notifications/show', {
        type: 'success',
        message: `Conversazione spostata in ${labelName}`,
      });

      // Eseguo l'assegnazione
      this.assignLabelToConversation(
        parseInt(conversationId, 10),
        parseInt(finalLabelId, 10)
      );
    },
  },
};
</script>
<style scoped>
.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s ease;
}
.slide-enter-from,
.slide-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}

.drop-target,
.drop-target-span {
  transition: all 0.2s ease;
}

.drag-over {
  background-color: rgba(66, 133, 244, 0.1) !important;
  box-shadow: inset 0 0 0 2px #4285f4;
}
</style>
