const state = {
  message: '',
  type: 'success',
  visible: false,
  timeout: null
};

const getters = {
  getMessage: $state => $state.message,
  getType: $state => $state.type,
  getVisible: $state => $state.visible
};

const mutations = {
  SET_NOTIFICATION($state, { message, type }) {
    $state.message = message;
    $state.type = type;
    $state.visible = true;
  },
  CLEAR_NOTIFICATION($state) {
    $state.visible = false;
  }
};

const actions = {
  show({ commit, state }, { message, type = 'success', duration = 3000 }) {
    // Clear any existing timeout
    if (state.timeout) {
      clearTimeout(state.timeout);
      state.timeout = null;
    }
    
    // Show notification
    commit('SET_NOTIFICATION', { message, type });
    
    // Auto-hide after duration
    state.timeout = setTimeout(() => {
      commit('CLEAR_NOTIFICATION');
    }, duration);
  },
  
  hide({ commit }) {
    commit('CLEAR_NOTIFICATION');
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}; 