import Vue from 'vue';
import ConversationApi from '../../api/inbox/conversation';
import types from '../mutation-types';

const state = {
  mineCount: 0,
  unAssignedCount: 0,
  allCount: 0,
  unreadCount: 0,
  unreadCountByInbox: {},
};

export const getters = {
  getStats: (state) => state,
  getUnreadCountByInbox: (state) => (inboxId) => {
    // console.log(
    //   'Stats: Getter getUnreadCountByInbox chiamato per inbox:',
    //   inboxId
    // );
    // console.log(
    //   'Stats: Stato corrente unreadCountByInbox:',
    //   state.unreadCountByInbox
    // );

    if (!inboxId) {
      return state.unreadCount;
    }

    const count = state.unreadCountByInbox[inboxId] || 0;
    // console.log('Stats: unreadCountByInbox for the current inbox:', count);
    return count;
  },
};

export const actions = {
  get: async ({ commit, rootState }, params = {}) => {
    try {
      // Se non vengono specificati parametri, usiamo quelli correnti dal router
      const currentRoute = rootState.route;
      const defaultParams = {};

      // Se siamo in una vista di inbox, utilizziamo l'inbox corrente
      if (currentRoute && currentRoute.params && currentRoute.params.inbox_id) {
        defaultParams.inboxId = currentRoute.params.inbox_id;
      }

      // Utilizziamo i parametri forniti o quelli di default
      const requestParams = {
        ...defaultParams,
        ...params,
      };

      // console.log('Stats: Action chiamata con params:', requestParams);
      const response = await ConversationApi.meta(requestParams);
      // console.log('Stats: Risposta API:', response);

      if (!response.data || !response.data.meta) {
        console.error('Stats: Dati mancanti nella risposta');
        return null;
      }

      const {
        data: { meta },
      } = response;

      // console.log('Stats: Meta ricevuto:', meta);

      commit(types.UPDATE_CONVERSATION_STATS, {
        ...meta,
        inboxId: requestParams.inboxId,
      });

      return meta;
    } catch (error) {
      console.error('Stats: Errore nel fetch:', error);
      return null;
    }
  },
};

export const mutations = {
  [types.UPDATE_CONVERSATION_STATS](state, meta) {
    // console.log('UPDATE_CONVERSATION_STATS init', meta);
    if (!meta || typeof meta !== 'object') {
      console.error('UPDATE_CONVERSATION_STATS: dati non validi:', meta);
      return;
    }

    // Assicuriamoci che tutti i conteggi siano interi non negativi
    const sanitizeCount = (count) => {
      const parsedCount = parseInt(count || 0, 10);
      return isNaN(parsedCount) || parsedCount < 0 ? 0 : parsedCount;
    };

    // Sanitizziamo anche i conteggi per inbox
    const sanitizeInboxCounts = (inboxCounts) => {
      if (!inboxCounts || typeof inboxCounts !== 'object') {
        return state.unreadCountByInbox || {};
      }

      const sanitizedCounts = {};
      Object.entries(inboxCounts).forEach(([inboxId, count]) => {
        sanitizedCounts[inboxId] = sanitizeCount(count);
      });

      return sanitizedCounts;
    };

    const updates = {
      mineCount: sanitizeCount(meta.mine_count),
      unAssignedCount: sanitizeCount(meta.unassigned_count),
      allCount: sanitizeCount(meta.all_count),
      unreadCount:
        meta.unread_count !== undefined
          ? sanitizeCount(meta.unread_count)
          : state.unreadCount || 0,
      unreadCountByInbox: meta.unread_count_by_inbox
        ? sanitizeInboxCounts(meta.unread_count_by_inbox)
        : state.unreadCountByInbox || {},
    };

    // Log per debug
    // if (meta.inboxId) {
    //   console.log(`Aggiornamento conteggi per inbox ${meta.inboxId}:`, {
    //     totale: updates.unreadCount,
    //     perInbox: updates.unreadCountByInbox[meta.inboxId] || 0,
    //   });
    // }

    Object.entries(updates).forEach(([key, value]) => {
      Vue.set(state, key, value);
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
