<template>
  <transition name="notification-fade">
    <div v-if="visible" class="notification-container">
      <div class="notification-content">
        <span class="notification-icon" v-if="type === 'success'">✅</span>
        <span class="notification-icon" v-else-if="type === 'error'">❌</span>
        <span class="notification-message">{{ message }}</span>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'DragDropNotification',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'success'
    }
  }
}
</script>

<style scoped>
.notification-container {
  position: fixed;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10000;
  background-color: #323232;
  color: white;
  border-radius: 4px;
  padding: 12px 24px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.2);
}

.notification-content {
  display: flex;
  align-items: center;
}

.notification-icon {
  margin-right: 12px;
}

.notification-fade-enter-active, .notification-fade-leave-active {
  transition: opacity 0.3s, transform 0.3s;
}

.notification-fade-enter-from, .notification-fade-leave-to {
  opacity: 0;
  transform: translateY(20px) translateX(-50%);
}
</style> 